<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '@/helpers/errorHandler';
import Uploader from "@/components/Quizes/uploader";
/**
 * Starter component
 */
export default {
  page: {
    title: `Question Form`,
  },
  data() {
    return {
      title: "Questions",
      baseUrl: process.env.VUE_APP_BASE_URL,
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Quizzes",
          href: "/quizes",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Uploader
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2>Upload Image Question</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="">
                    <Uploader :url="`${baseUrl}quiz/add_question_image/${this.$route.params.question}`" />
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="$router.push('/quizes/'+$route.params.quiz+'/details')">Done</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>